.homeIcon {
  position: absolute;
  top: -10px;
  left: 15px;
  cursor: pointer;
  width: 90px;
  height: 70px;
  z-index: 1;
}

@media screen and (max-width: 525px) and (max-height: 525px) {
  .homeIcon {
      display: none;
  }
}
